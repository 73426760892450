import React, {Component} from 'react';
import style from './index.module.scss';

import { zeroPad, secondsToTime } from '../../utils'

import thyme from '../../assets/thyme.svg'

type WishboneProps = {}
type WishboneState = {
  hours: string
  minutes: string
  seconds: string
}

class WishboneContainer extends React.Component<WishboneProps, WishboneState> {
  state  = {
    hours: "00",
    minutes: "00",
    seconds: "00"
  }

  componentDidMount() {
    setInterval(()=> {
      this.getTime()
    }, 1000)
  }

  getTime() {
    const secondsSinceEpoch = Math.round(Date.now() / 1000)
    const secondsLeft = 1637787600 - secondsSinceEpoch
    const time = secondsToTime(secondsLeft)

    const hours = zeroPad(time.h, 2)
    const minutes = zeroPad(time.m, 2)
    const seconds = zeroPad(time.s, 2)

    this.setState({
      hours,
      minutes,
      seconds,
    })
  }

  render() {
    return (
      <div className={style.WishboneContainer}>
        <div className={style.CountDownTimer}>
          T  - {this.state.hours + ":" + this.state.minutes + ":" + this.state.seconds}
        </div>
        <img src={thyme} />
        <div className={style.WishboneContainerTitle}>
          Buy Ingredients
        </div>
        <div className={style.WishboneContainerDescription}>
          Buy Ingredient NFTs to cook a thanksgiving dinner
        </div>
      </div>
    );
  }
}

export default WishboneContainer;
