import React, {Component} from 'react';
import style from './index.module.scss';

type ProfileViewProps = {
  bns?: any
  userData: any
}
type ProfileViewState = {}

class ProfileView extends React.Component<ProfileViewProps, ProfileViewState> {
  logout() {
    localStorage.clear();
    window.location.reload();
  }

  returnAddress() {
    const userData: any = this.props.userData
    if(this.props.bns && this.props.bns.name && this.props.bns.namespace) {
      return this.props.bns.name + "." + this.props.bns.namespace
    } else if(userData && userData.profile && userData.profile.stxAddress) {
      return userData.profile.stxAddress.mainnet
    }
  }

  render() {
    return (
      <div className={style.ProfileView}>
        <div className={style.ProfileViewWelcome}>
          Welcome <span className={style.ProfileViewAddress}>{this.returnAddress()}</span>
        </div>
        <div
          className={style.ProfileViewLogout}
          onClick={this.logout.bind(this)}>
          LOGOUT
        </div>
      </div>
    );
  }
}

export default ProfileView;

 