import React, {Component} from 'react';
import style from './index.module.scss';

import dinner from '../../assets/dinner.svg'

import { zeroPad, secondsToTime } from '../../utils'

type CookDinnerContainerProps = {}
type CookDinnerContainerState = {
  hours: string
  minutes: string
  seconds: string
}

class CookDinnerContainer extends React.Component<CookDinnerContainerProps, CookDinnerContainerState> {
  state  = {
    hours: "00",
    minutes: "00",
    seconds: "00"
  }

  componentDidMount() {
    setInterval(()=> {
      this.getTime()
    }, 1000)
  }

  getTime() {
    const secondsSinceEpoch = Math.round(Date.now() / 1000)
    const secondsLeft = 1637866800 - secondsSinceEpoch
    const time = secondsToTime(secondsLeft)

    const hours = zeroPad(time.h, 2)
    const minutes = zeroPad(time.m, 2)
    const seconds = zeroPad(time.s, 2)

    this.setState({
      hours,
      minutes,
      seconds,
    })
  }

  render() {
    return (
      <div className={style.WishboneContainer}>
        <div className={style.CountDownTimer}>
          T  - {this.state.hours + ":" + this.state.minutes + ":" + this.state.seconds}
        </div>
        <img src={dinner} />
        <div className={style.WishboneContainerTitle}>
          Cook Dinner
        </div>
        <div className={style.WishboneContainerDescription}>
          Use Ingredient NFTs to cook some dishes for thanksgiving dinner
        </div>
      </div>
    );
  }
}

export default CookDinnerContainer;

 