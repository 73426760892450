import React, {Component} from 'react';
import style from './App.module.scss';

import TitleBar from './Components/TitleBar'
import ConnectButton from './Components/ConnectButton'
import ProfileView from './Components/ProfileView'

import SetTheTableComponent from './Components/SetTheTableComponent'
import WishboneContainer from './Components/WishboneContainer'
import IngredientsContainer from './Components/IngredientsContainer'
import CookDinnerContainer from './Components/CookDinnerContainer'

type AppProps = {}
type AppState = {
  userData: any,
  bns: any
}

class App extends React.Component<AppProps, AppState> {
  state = {
    userData: undefined, 
    bns: undefined
  }

  componentDidMount() {
    try {
      const userData = localStorage.getItem('userData');
      if (userData) {
        this.setState({
          'userData': JSON.parse(userData)
        }, () => {
          const userData: any = this.state.userData
          if(userData && userData.profile && userData.profile.stxAddress) {  
            fetch('https://stxnft.com/api/bns?address=' + userData.profile.stxAddress.mainnet)
              .then(res => res.json())
              .then((data) => {
                if(data.name  && data.namespace) {
                  this.setState({
                    bns: data
                  })
                }
              })
          }
        })
      }
    } catch {}
  }

  render() {
    const userData: any = this.state.userData
    
    return (
      <div className={style.App}>
        <TitleBar />
        <div className={style.AppInteractiveNFTs}>
          <div>
            The first <span className={style.AppInteractive}>Interactive</span> and <span className={style.AppInteractive}>Composable</span> NFTs secured by <span className={style.AppInteractive}>Bitcoin</span>
          </div>
        </div>
        <div className={style.AppConnectBar}>
          {!this.state.userData &&
            <ConnectButton />
          }
          {this.state.userData &&
            <ProfileView
              bns={this.state.bns}
              userData={this.state.userData}/>
          }
        </div>
        <div className={style.AppConnectContainer}>
          <WishboneContainer />
          <IngredientsContainer />
          <CookDinnerContainer />
          <SetTheTableComponent />
        </div>
      </div>
    );
  }
}

export default App;
