import React, {Component} from 'react';
import style from './index.module.scss';

import ActiveLogo from '../ActiveLogo'

class TitleBar extends React.Component {
  render() {
    return (
      <div className={style.TitleBar}>
        <ActiveLogo />
      </div>
    );
  }
}

export default TitleBar;