import React, {Component} from 'react';
import style from './index.module.scss';

import logo from '../../assets/logo.svg'

type SocketPosition = {
  x: number,
  y: number
}

type ActiveLogoProps = {}
type ActiveLogoState = {
  socketOne: SocketPosition,
  socketTwo: SocketPosition
}

class ActiveLogo extends React.Component<ActiveLogoProps, ActiveLogoState> {
  private eyeOne: React.RefObject<HTMLInputElement>;
  private eyeTwo: React.RefObject<HTMLInputElement>;

  state = {
    socketOne: {x: 11, y: 11},
    socketTwo: {x: 11, y: 11}
  }

  constructor(props: ActiveLogoProps) {
    super(props);
    this.eyeOne = React.createRef();
    this.eyeTwo = React.createRef();
  }

  componentDidMount() {

    document.addEventListener("mousemove", this.handleCursor);
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.handleCursor);
  }

  handleCursor = (e: MouseEvent) => {
    if (this.eyeOne.current
      && this.eyeOne.current != undefined
      && this.eyeTwo.current
      && this.eyeTwo.current != undefined) {
      const eyeOnePos = this.eyeOne.current.getBoundingClientRect();
      const eyeTwoPos = this.eyeTwo.current.getBoundingClientRect();

      const eyeOneCenter = {
        x: (eyeOnePos.x + eyeOnePos.width/2),
        y: (eyeOnePos.y + eyeOnePos.height/2)
      }

      const eyeTwoCenter = {
        x: (eyeTwoPos.x + eyeTwoPos.width/2),
        y: (eyeTwoPos.y + eyeTwoPos.height/2)
      }

      const cursorPos = {
        x: e.clientX,
        y: e.clientY
      }

      const oneAdjacent = (cursorPos.x - eyeOneCenter.x)
      const oneOpposite = (eyeOneCenter.y - cursorPos.y)
      const oneRadians = Math.atan2(oneOpposite, oneAdjacent)
      const oneMagnitude = Math.sqrt(Math.pow((eyeOneCenter.x - cursorPos.x), 2) + Math.pow((eyeOneCenter.y - cursorPos.y), 2))
      const oneDistance = parseInt((6 * Math.tanh(oneMagnitude / 100)) + '', 10)

      const twoAdjacent = (cursorPos.x - eyeTwoCenter.x)
      const twoOpposite = (eyeTwoCenter.y - cursorPos.y)
      const twoRadians = Math.atan2(twoOpposite, twoAdjacent)
      const twoMagnitude = Math.sqrt(Math.pow((eyeTwoCenter.x - cursorPos.x), 2) + Math.pow((eyeTwoCenter.y - cursorPos.y), 2))
      const twoDistance = parseInt((6 * Math.tanh(twoMagnitude / 100)) + '', 10)

      // console.log(oneRadians)
      // console.log(oneDistance)

      // console.log(twoRadians)
      // console.log(twoDistance)

      const oneY = parseInt((-1.0 * oneDistance * Math.sin(oneRadians) + 6) + '', 10)
      const oneX = parseInt((oneDistance * Math.cos(oneRadians) + 6) + '', 10)

      const twoY = parseInt((-1.0 * twoDistance * Math.sin(twoRadians) + 6) + '', 10)
      const twoX = parseInt((twoDistance * Math.cos(twoRadians) + 6) + '', 10)
      
      this.setState({
        socketOne:{
          x: oneX, 
          y: oneY
        },
        socketTwo:{
          x: twoX, 
          y: twoY
        }
      })
    }
  }

  render() {
    return (
      <div className={style.ActiveLogo}>
        <div className={style.ActiveLogoContainer}>
          <img src={logo} />
        </div>
        <div
          className={style.EyeSocket}
          style={{"top": '12px', 'left': '20px'}}
          ref={this.eyeOne}>
          <div style={{"position": 'relative'}}>
            <div
              className={style.EyeHole}
              style={{
                'top': this.state.socketOne.y + 'px',
                'left': this.state.socketOne.x + 'px'
              }}>
            </div>
          </div>
        </div>
        <div
          className={style.EyeSocket}
          style={{"top": '12px', 'left': '46px'}}
          ref={this.eyeTwo}>
          <div style={{"position": 'relative'}}>
            <div
              className={style.EyeHole}
              style={{
                'top': this.state.socketTwo.y + 'px',
                'left': this.state.socketTwo.x + 'px'
              }}>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ActiveLogo;